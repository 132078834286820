import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { Link } from 'react-router-dom';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';

import { toDashed } from 'utils/string';

class QueryInput extends Component {
  static suggestionValue(suggestion) {
    return suggestion.name;
  }

  static getSectionSuggestions(section) {
    return section.items;
  }

  static renderSectionTitle(section) {
    return section.items.length && <strong>{section.title}</strong>;
  }

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: [],
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    this.props.fetchSpecializationsRequest();
  }

  componentDidMount() {
    const { value } = this.props;
    (() => {
      if (value) {
        this.setState({
          value,
        });
      }
    })();
  }

  componentWillReceiveProps(props) {
    const { value } = this.state;
    const filtered = !value
      ? props.specializations
      : props.specializations.filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) === 0);

    this.setState({
      suggestions: [
        {
          title: 'Speciality',
          items: filtered,
        },
        {
          title: 'Specialists',
          items: props.specialists.map(s => ({
            name: `${s.firstName} ${s.lastName}`,
            type: 'specialist',
            speciality: s.specialization,
            path: `/specialist/${get(s.practices, '0.town', '-')}/${toDashed(s.specialization || '')}/${s.slug}`,
          })),
        },
        {
          title: 'Practices',
          items: props.practices.map(p => ({
            name: p.name,
            type: 'practice',
            path: `/practice/${p.town}/${toDashed(p.borough || '')}/${p.slug}`,
          })),
        },
      ],
    });
  }

  shouldComponentUpdate(props, state) {
    return (
      this.state.value !== state.value ||
      this.props.specialists.length !== props.specialists.length ||
      this.props.practices.length !== props.practices.length ||
      this.props.specializations.length !== props.specializations.length
    );
  }

  componentWillUpdate(props, state) {
    this.props.setSearchValue(state.value);
  }

  onChange(_, { newValue }) {
    const filtered = !newValue
      ? this.props.specializations
      : this.props.specializations.filter(item => item.name.toLowerCase().indexOf(newValue.toLowerCase()) === 0);

    this.setState(prevState => ({
      value: newValue,
      suggestions: [
        {
          title: 'Speciality',
          items: filtered,
        },
        ...prevState.suggestions.slice(1, 3),
      ],
    }));
  }

  suggestionsFetchRequested = ({ value }) => {
    this.props.fetchSpecialists(value);
    this.props.fetchPractices(value);
  };

  suggestionsClearRequested = () => {
    this.setState(prevState => ({
      suggestions: [...prevState.suggestions.slice(0, 2)],
    }));
  };

  resetInput = () => {
    this.setState({
      value: '',
    });
  };

  renderSuggestion = suggestion => {
    // for speciality
    if (!suggestion.type) {
      return <div>{suggestion.name}</div>;
    }

    // for specialist
    if (suggestion.type === 'specialist') {
      return (
        <Link to={suggestion.path.toLowerCase()}>
          <div>{suggestion.name}</div>
          <div className="is-size-7">{suggestion.speciality}</div>
        </Link>
      );
    }

    // for practice
    return (
      <Link to={suggestion.path.toLowerCase()}>
        <div>{suggestion.name}</div>
      </Link>
    );
  };

  render() {
    const { i18n } = this.props;

    const inputProps = {
      value: this.state.value,
      onChange: this.onChange,
      onFocus: this.resetInput,
      placeholder: i18n._('Speciality, doctor or practice name'),
      className: 'input name',
    };

    return (
      <Autosuggest
        multiSection
        suggestions={this.state.suggestions}
        getSuggestionValue={QueryInput.suggestionValue}
        onSuggestionsFetchRequested={this.suggestionsFetchRequested}
        onSuggestionsClearRequested={this.suggestionsClearRequested}
        renderSectionTitle={QueryInput.renderSectionTitle}
        getSectionSuggestions={QueryInput.getSectionSuggestions}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

QueryInput.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func.isRequired,
  }),
  fetchPractices: PropTypes.func,
  fetchSpecialists: PropTypes.func,
  fetchSpecializationsRequest: PropTypes.func,
  setSearchValue: PropTypes.func,
  value: PropTypes.string,
  practices: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      town: PropTypes.string,
      borough: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  specialists: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })
  ),
  specializations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ).isRequired,
};

export default withI18n()(QueryInput);

// @flow

import React from 'react';
import Scroll from 'react-scroll';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';

import SearchBar from 'modules/searchBar';

import styles from './IndexMain.scss';

const IndexMain = () => (
  <div className="container">
    <div className={styles.wrapper}>
      <h1 className={`has-text-white ${styles.title}`}>
        <Trans>Book your healthcare appointment online</Trans>
      </h1>
      <h5 className="title is-5 has-text-white has-text-weight-normal is-hidden-mobile">
        <Trans>Search and book instantly.</Trans> <div className="is-hidden-tablet" />
        <Trans>It’s simple, secure and free !</Trans>
      </h5>

      <div className={`${styles.searchBar} pt-5 pr-5 pb-5 pl-5`}>
        <SearchBar />
      </div>
      <div className={styles.bottomLink}>
        <span className="is-size-5 has-text-warning has-text-weight-semibold">
          <Trans>new!</Trans>
        </span>
        <span className="fontfamily-roboto">
          <Link
            to="/specialists/london/gp-home-visit"
            className="button button-link has-text-white has-text-weight-semibold"
          >
            <u>
              <Trans>GP Home Visit</Trans>
            </u>
          </Link>
          <Link
            to="/specialists/london/holistic-therapist"
            className="button button-link has-text-white has-text-weight-semibold"
          >
            <u>
              <Trans>Holistic therapy/Complementary medicine</Trans>
            </u>
          </Link>
        </span>
        <div className={`has-text-centered is-hidden-tablet ${styles.downLink}`}>
          <Scroll.Link to="index_partner" spy smooth duration={800}>
            <i className="fa fa-angle-down" />
          </Scroll.Link>
        </div>
      </div>
    </div>
  </div>
);

export default IndexMain;

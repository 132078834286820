import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import classNames from 'classnames';
import styles from './IndexPartners.scss';

const IndexSupport = () => (
  <div id="index_partner" className="has-text-centered pt-40 pb-40">
    <div className="container">
      <div className="wrap">
        <h5 className="is-size-4 has-text-primary pb-30">
          <Trans>Our partners</Trans>
        </h5>
        <div className={classNames('columns', styles.partners)}>
          <div className="column">
            <div className="columns is-mobile">
              <div className="column is-one-third-mobile">
                <Link to="/practice/london/kensington-and-chelsea/medicare-francais">
                  <img src="/images/partner/logo_medicare.jpg" alt="Medicare logo" />
                </Link>
              </div>
              <div className="column is-one-third-mobile">
                <img src="/images/partner/qare-contrast.svg" alt="Qare logo" />
              </div>
              <div className="column is-one-third-mobile">
                <Link to="/practice/london/westminster/private-gp-chez-vous">
                  <img src="/images/partner/PRIVATE_GP-logo.png" alt="Private GP" />
                </Link>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="columns is-mobile">
              <div className="column is-one-third-mobile">
                <img src="/images/partner/happy_valley_net.png" alt="HappyValleyNet.com Logo" />
              </div>
              <div className="column is-one-third-mobile">
                <Link to="/practice/london/westminster/gpdq">
                  <img src="/images/partner/gpdq.png" alt="GPDQ.com Logo" />
                </Link>
              </div>
              <div className="column is-one-third-mobile">
                <Link to="/practice/london/lambeth/make-me-feel">
                  <img src="/images/partner/logo_make_me_feel.png" alt="Make Me Feel Logo" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <span className="clear" />
      </div>
    </div>
  </div>
);

export default IndexSupport;

import React from 'react';

import IndexDescription from './components/indexDescription';
import IndexMain from './components/indexMain';
import IndexPartners from './components/indexPartners';
import IndexMedia from './components/indexMedia';
import IndexService from './components/indexService';
import BookDoctor from './components/bookDoctor';
import PrivateGP from './components/privateGP';
import NHSOrPrivate from './components/NHSOrPrivate';

import styles from './IndexView.scss';

const IndexView = () => (
  <div>
    <div className={styles.description}>
      <IndexMain />
      <IndexDescription />
    </div>

    <IndexMedia />
    <IndexService />
    <BookDoctor />
    <PrivateGP />
    <NHSOrPrivate />
    <IndexPartners />
  </div>
);

export default IndexView;

import React from 'react';
import { Link } from 'react-router-dom';

import styles from './IndexMedia.scss';

const IndexMedia = () => (
  <div className={`has-text-centered has-background-grey-lighter pt-15 pb-10 ${styles.container}`}>
    <div className="container">
      <ul>
        <li className="first">
          <Link to="/press">
            <img src="/images/media/forbes@1x.png" alt="Forbes logo" />
          </Link>
        </li>
        <li>
          <Link to="/press">
            <img src="/images/media/logo-winner@1x.png" alt="voom semifinalist logo" />
          </Link>
        </li>
        <li>
          <Link to="/press">
            <img src="/images/media/camden-business@1x.png" alt="hc best start up business logo" />
          </Link>
        </li>
        <li className="last">
          <Link to="/press">
            <img src="/images/media/guardian.png" alt="guardian log" />
          </Link>
        </li>
      </ul>
    </div>
  </div>
);

export default IndexMedia;

import React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';

import styles from './BookDoctor.scss';

const BookDoctor = () => (
  <div className={styles.wrapper}>
    <div className={styles.book}>
      <div className={classNames(styles.image)}>
        <img src="/images/background/BookDoctor.jpg" alt="" />
      </div>
      <div className={styles.description}>
        <h1 className={classNames('has-text-weight-light has-text-dark is-size-1 is-size-3-mobile', styles.title)}>
          <Trans>Book a doctor in the UK</Trans>
        </h1>
        <div className={classNames('has-text-dark-blue mt-50 mb-20 mt-0-mobile', styles.text)}>
          <p>
            <Trans>
              If you are trying to book a private healthcare professional, then look no further. Findoc has made it
              possible to book doctors in London with a few easy clicks. Whether you need to book a dentist or a doctor
              or maybe even an osteopath or cosmetic surgery, you will find an expert instantly with Findoc!
            </Trans>
          </p>
          <p>
            <Trans>
              Our aim is to give you the best possible service. That is why we have built lasting relationships with
              healthcare practitioner to ensure you receive the best care possible.
            </Trans>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default BookDoctor;

import React from 'react';
import { Trans } from '@lingui/macro';

import styles from './IndexDescription.scss';

const IndexDescription = () => (
  <div id="index-description" className={`has-text-centered pt-40 pb-40 ${styles.container}`}>
    <div className="container">
      <div className="columns">
        <div className="column">
          <div className={styles.image}>
            <img src="/images/icons/find.svg" alt="" />
          </div>
          <h5 className="title is-5 has-text-white fontweight-600">
            <span>
              <Trans>FIND</Trans>
            </span>
            <small className="has-text-weight-normal has-text-left-mobile mt-5">
              <Trans>
                Find top doctors <br /> by speciality and area.
              </Trans>
            </small>
          </h5>
        </div>
        <div className="column">
          <div className={styles.image}>
            <img src="/images/icons/book.svg" alt="" />
          </div>
          <h5 className="title is-5 has-text-white fontweight-600">
            <span>
              <Trans>BOOK</Trans>
            </span>
            <small className="has-text-weight-normal has-text-left-mobile mt-5">
              <Trans>
                Book appointment at your
                <br />
                preferred time.
              </Trans>
            </small>
          </h5>
        </div>
        <div className="column">
          <div className={styles.image}>
            <img src="/images/icons/visit.svg" alt="" />
          </div>
          <h5 className="title is-5 has-text-white fontweight-600">
            <span>
              <Trans>VISIT</Trans>
            </span>
            <small className="has-text-weight-normal has-text-left-mobile mt-5">
              <Trans>
                Your appointment is confirmed.
                <br />
                Visit the doctor.
              </Trans>
            </small>
          </h5>
        </div>
      </div>
    </div>
  </div>
);

export default IndexDescription;

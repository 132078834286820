import React from 'react';
import { Trans } from '@lingui/macro';

import classNames from 'classnames';
import styles from './NHSOrPrivate.scss';

class NHSOrPrivate extends React.PureComponent {
  state = {
    collapsed: true,
  };

  toggleShow = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
  };

  render() {
    const { collapsed } = this.state;
    return (
      <div className={classNames(styles.wrapper, collapsed ? styles.wrapperCollapsed : styles.wrapperExpanded)}>
        <div className={styles.book}>
          <div className={classNames(styles.image)}>
            <img src="/images/background/NHSOrPrivate.jpg" alt="Guy using tablet" />
          </div>
          <div className={styles.description}>
            <h1 className={classNames('has-text-weight-light has-text-dark is-size-1 is-size-3-mobile', styles.title)}>
              <Trans>
                NHS vs Private Health
                <br />
                Care Comparison
              </Trans>
            </h1>
            <div className={classNames('has-text-primary-dark', styles.text)}>
              <Trans>
                <p>
                  <strong>How do I find a doctor?</strong> – If you need a certain treatment, on the NHS you need to ask
                  your GP to refer you to a consultant. For the private option, you get to choose your own. You can make
                  searches online, such as ‘private doctors near me’. If you search ‘doctors near me’, NHS doctors will
                  not appear, only private ones. You can also search on Findoc
                </p>
                <p>
                  <strong>When will my appointment be?</strong> – for NHS appointments you may have to join a long
                  waiting list for your treatment. If you choose private healthcare, the wait should be much shorter
                </p>
                <p>
                  <strong>Can I choose when my appointment is?</strong> – with the NHS, you generally have to accept the
                  appointment time you’re given. Private healthcare appointments are much more flexible
                </p>
                <p>
                  <strong>Can I choose who will treat me?</strong> – private healthcare allows you to choose exactly
                  which doctor will be treating you. With the NHS, you’re allocated a doctor, usually one local to you.
                </p>
                <p>
                  <strong>Who will be treating you?</strong> – if you choose the private option, the doctor you select
                  will be the one that treats you, even if you select an experienced consultant. On the NHS, it is
                  unlikely to be a consultant who will treat you, rather a member of their team
                </p>
              </Trans>
              {!collapsed && (
                <div>
                  <Trans>
                    <p>
                      <strong>Where will I be treated?</strong> – treatments provided by the NHS will take place in a
                      public hospital, usually local to you. If you must stay overnight, you’ll be on a public ward with
                      other people. When you choose private healthcare, you’ll stay in a private hospital with
                      high-quality private rooms
                    </p>
                    <p>
                      <strong>What will my treatment be?</strong> – private healthcare enables you to receive the
                      treatment you want. NHS doctors are restricted by the treatments they can prescribe, and often
                      lack the innovative technology present in private surgeries
                    </p>
                    <p>
                      <strong>How much will it cost?</strong> – most treatments on the NHS are free. The cost of private
                      healthcare varies depending on the treatment and the practice you visit.
                    </p>
                  </Trans>
                </div>
              )}
              <button onClick={this.toggleShow} className="button button-link has-text-primary pl-0">
                <span className="icon is-small">
                  <i className={`fa fa-angle-${collapsed ? 'down' : 'up'}`} />
                </span>
                <span>{collapsed ? <Trans>Show more</Trans> : <Trans>Show less</Trans>}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NHSOrPrivate.propTypes = {};

export default NHSOrPrivate;

/* global google */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { withI18n } from '@lingui/react';

import { toDashed } from 'utils/string';

import styles from './LocationInput.scss';

class LocationInput extends Component {
  state = {
    value: '',
    town: '',
    placeSuggestions: [],
  };

  componentDidMount() {
    const { value, town } = this.props;

    this.autocompleteService = new window.google.maps.places.AutocompleteService();

    (() => {
      this.setState({
        value: value || '',
        town: town || '',
      });
    })();
  }

  shouldComponentUpdate(props, state) {
    return (
      this.state.value !== state.value ||
      // this.state.value === state.value &&
      this.state.placeSuggestions.length !== state.placeSuggestions.length ||
      this.placeDidChange(this.state, state) ||
      this.props.required !== props.required
    );
  }

  componentDidUpdate() {
    this.props.setLocationValue(this.state.value);
    this.props.setTownValue(toDashed(this.state.town));
  }

  onChange = ({ target: { value } }) => {
    this.setState({
      value,
    });
  };

  getSuggestionValue = suggestion => suggestion.description;

  suggestionsFetchRequested = ({ value }) => {
    this.autocompleteService.getPlacePredictions(
      {
        input: value,
        types: ['geocode'],
        componentRestrictions: { country: 'uk' },
      },
      result => {
        this.setState({
          placeSuggestions: result || [],
        });
      }
    );
  };

  suggestionsClearRequested = () => {
    this.setState({
      placeSuggestions: [],
    });
  };

  suggestionSelected = (e, { suggestion }) => {
    const { terms } = suggestion;

    let town;

    if (terms.length >= 2) {
      town = terms[terms.length - 2].value;
    } else {
      town = terms[0].value;
    }

    this.setState({
      value: suggestion.description,
      town,
    });
  };

  placeDidChange = (state, newState) => {
    if (!newState || !newState.placeSuggestions.length) return false;

    for (let i = 0; i < state.placeSuggestions.length; i += 1) {
      if (state.placeSuggestions[i].id !== newState.placeSuggestions[i].id) return true;
    }

    return false;
  };

  // geolocation function is used
  fillLocation = () => {
    navigator.geolocation.getCurrentPosition(position => {
      const currentPos = position.coords;
      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(currentPos.latitude, currentPos.longitude);

      geocoder.geocode({ location: latlng }, (data, status) => {
        if (status === google.maps.GeocoderStatus.OK && data[0]) {
          // eslint-disable-next-line camelcase
          const { address_components } = data[0];
          const country = address_components.find(item => item.types.indexOf('country') !== -1);

          const postalCode = address_components.find(item => item.types.indexOf('postal_code') !== -1);
          let town = address_components.find(item => item.types.indexOf('locality') !== -1);
          if (!town) {
            town = address_components.find(item => item.types.indexOf('administrative_area_level_1') !== -1);
          }

          let value = country.long_name;
          if (town) {
            value = `${town.long_name}, ${value}`;
          }
          if (postalCode) {
            value = `${postalCode.long_name}, ${value}`;
          }

          if (town) {
            this.setState({
              value,
              town: town.long_name,
            });
          }
        }
      });
    });
  };

  handleBlur = () => {
    const { placeSuggestions } = this.state;

    if (placeSuggestions.length) {
      this.suggestionSelected(null, {
        suggestion: placeSuggestions[0],
      });
    }
  };

  resetInput = () => {
    this.setState({
      value: '',
    });
  };

  renderSuggestion = suggestion => (
    <div>
      <div className="location-suggestion">{suggestion.terms[0].value}</div>
      <small>
        {suggestion.terms
          .slice(1)
          .map(t => t.value)
          .join(', ')}
      </small>
    </div>
  );

  render() {
    const { i18n } = this.props;

    const inputProps = {
      value: this.state.value,
      onChange: this.onChange,
      onFocus: this.resetInput,
      className: 'input location',
      placeholder: i18n._('Location'),
      onBlur: this.handleBlur,
    };

    return (
      <div className={`${styles.container} ${this.props.required && 'has-error'}`}>
        <Autosuggest
          suggestions={this.state.placeSuggestions}
          inputProps={inputProps}
          onSuggestionsFetchRequested={this.suggestionsFetchRequested}
          onSuggestionsClearRequested={this.suggestionsClearRequested}
          onSuggestionSelected={this.suggestionSelected}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          highlightFirstSuggestion
        />
        <button className={styles.btnPick} onClick={this.fillLocation}>
          <i className="fa fa-location-arrow" />
        </button>
      </div>
    );
  }
}

LocationInput.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func.isRequired,
  }),
  setTownValue: PropTypes.func,
  setLocationValue: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
  town: PropTypes.string,
};

export default withI18n()(LocationInput);

// @flow

import React from 'react';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';

import styles from './IndexService.scss';

type State = {
  readLess: boolean,
};

class IndexService extends React.Component<null, State> {
  state = {
    readLess: true,
  };

  handleReadLessClick = () => {
    this.setState(prevState => ({
      readLess: !prevState.readLess,
    }));
  };

  render() {
    return (
      <div className={`${styles.container} has-text-centered pt-50 pb-20`}>
        <div className="container">
          <div className="title ">
            <h2 className={`${styles.title} has-text-weight-light has-text-primary pb-50 is-size-3-mobile `}>
              <Trans>Are you a five-star doctor?</Trans>
              <small className="mt-20 has-text-dark is-hidden-mobile">
                <Trans>
                  If you are, then millions of people across London are trying to find a doctor just like you.
                  <br />
                  If you’re looking to grow your private practice, you need to get listed on Findoc today.
                </Trans>
              </small>
            </h2>
          </div>
          <div className={`columns is-mobile ${styles.main}`}>
            <div className="column is-half-mobile">
              <div className="img">
                <img src="/images/icons/icon Your rebookings@1x.png" alt="Increase patient rebookings" />
              </div>
              <h6 className="has-text-weight-normal">
                <Trans>Increase your rebookings</Trans>
              </h6>
            </div>
            <div className="column is-half-mobile">
              <div className="img">
                <img src="/images/icons/icon increase your online@1x.png" alt="Increase online visibility" />
              </div>
              <h6 className="has-text-weight-normal">
                <Trans>Increase your online visibility</Trans>
              </h6>
            </div>
            <div className="column is-half-mobile">
              <div className="img">
                <img src="/images/icons/icon Last minutes@1x.png" alt="Fill all openings" />
              </div>
              <h6 className="has-text-weight-normal">
                <Trans>Fill your last-minute openings</Trans>
              </h6>
            </div>
            <div className="column is-half-mobile">
              <div className="img">
                <img src="/images/icons/icon book online 24-7@1x.png" alt="Book 24/7" />
              </div>
              <h6 className="has-text-weight-normal">
                <Trans>A new way to book online, 24/7</Trans>
              </h6>
            </div>
          </div>
          <span className="clear" />
          <div className="has-text-centered">
            <Link to="/join-us" className="button is-rounded is-medium is-primary has-text-white mt-40 fontweight-600">
              <Trans>Find out more</Trans>
            </Link>
          </div>
          <div className={styles.description}>
            <div className={`pt-80 pb-60 ${styles.descriptionTitle}`}>
              <h3 className="has-text-weight-semibold is-size-5 is-size-6-mobile">
                <Trans>Here’s how Findoc helps power your practice</Trans>{' '}
              </h3>
              <span role="button" tabIndex={0} onClick={this.handleReadLessClick} className={styles.readLess}>
                <i className={`fa ${this.state.readLess ? 'fa-angle-down' : 'fa-angle-up'}`} />{' '}
                <span className="has-text-primary">
                  {this.state.readLess ? <Trans>Read more</Trans> : <Trans>Read less</Trans>}
                </span>
              </span>
            </div>
            {!this.state.readLess && (
              <div className="has-text-justified">
                <div className="columns">
                  <div className="column">
                    <strong className="has-text-primary">
                      <Trans>Increase your rebookings</Trans>
                    </strong>{' '}
                    <Trans>
                      - 81% of patients who rebook appointments in the same speciality choose the same practice. Findoc
                      makes it super-easy for patients to rebook with you.
                    </Trans>
                  </div>
                  <div className="column">
                    <strong className="has-text-primary">
                      <Trans>Fill your last-minute openings</Trans>
                    </strong>{' '}
                    <Trans>
                      - Findoc shows patients your soonest available appointment slots, so they can book consultations
                      at short-notice.
                    </Trans>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <strong className="has-text-primary">
                      <Trans>Increase your online visibility</Trans>
                    </strong>{' '}
                    <Trans>
                      - Findoc will get your practice in front of potential patients when they perform online searches
                      for terms such as ‘doctors near me’, ‘best doctors near me’, ‘find a doctor near me’, and more.
                      Findoc also shows visitors a profile screen, showing your credentials and specialities.
                    </Trans>
                  </div>
                  <div className="column">
                    <strong className="has-text-primary">
                      <Trans>Give your patients a new way to book online, 24/7</Trans>
                    </strong>{' '}
                    <Trans>
                      - booking an appointment with Findoc is easy and fast. Plus, patients can book appointments
                      whenever they wish. All Findoc’s features give you more opportunities to delight your patients,
                      increasing the chances of them becoming regular visitors.
                    </Trans>
                  </div>
                </div>
                <div className="has-text-weight-semibold has-text-centered is-size-6 mt-60">
                  <Trans>
                    If you’re a five-star doctor looking to boost the number of private patients you can see,
                    <br />
                    it’s time to partner with Findoc.
                  </Trans>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default IndexService;

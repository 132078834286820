// @flow

import React from 'react';
import { Trans, t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import styles from './PrivateGP.scss';

const PrivateGP = () => (
  <I18n>
    {({ i18n }) => (
      <div className={styles.wrapper}>
        <div className={styles.doctorImage}>
          <img src="/images/doctor.png" alt="" />
        </div>
        <div className={classNames('has-text-centered-mobile has-text-primary-dark', styles.description)}>
          <h1 className={classNames('title is-size-1 is-size-3-mobile fontweight-400', styles.title)}>
            <Trans>Private GP home visit.</Trans>
          </h1>
          <div className={styles.flag}>
            <img src="/images/icons/Flag_of_France.svg" alt="" />
          </div>
          <div className="is-size-4 is-size-6-mobile">
            <Trans>
              Within an average of 90 minutes you can be visited by a trusted GP to your home, office or hotel in
              central London 24/7.
            </Trans>
          </div>
          <div>
            <Link
              to="/practice/london/westminster/private-gp-chez-vous"
              className={classNames('button is-rounded is-primary-dark is-large', styles.bookHome)}
            >
              <Trans>BOOK HOME VISIT</Trans>
            </Link>
          </div>
          <div>
            <Trans>
              Alternatively, call{' '}
              <a href={`tel:${i18n._(t`0800 437 0000`)}`} className={classNames('has-text-weight-bold', styles.phone)}>
                0800 437 0000
              </a>
            </Trans>
          </div>
        </div>
      </div>
    )}
  </I18n>
);

export default PrivateGP;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import queryString from 'query-string';

import { actions } from 'modules/index/actions';
import { actions as specializationActions } from 'modules/specializations/actions';
import { selectors as specializationSelectors } from 'modules/specializations/reducer';

import { toDashed } from 'utils/string';
import QueryInput from './QueryInput';
import LocationInput from './LocationInput';

import styles from './SearchBar.scss';

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.setNameValue = this.setSearchValue.bind(this, 'name');
    this.setSpecializationValue = this.setSearchValue.bind(this, 'specialization');
    this.setLocationValue = this.setSearchValue.bind(this, 'location');
    this.setTownValue = this.setSearchValue.bind(this, 'town');
  }

  setSearchValue(type, value) {
    const state = {
      [type]: value,
    };
    if (type === 'location') state.required = !this.state.location;

    this.setState(state);
  }

  handleSubmit = ev => {
    ev.preventDefault();
    ev.stopPropagation();

    if (!this.state.location) {
      return this.setState({
        required: true,
      });
    }

    this.props.resetFetchingState();

    window.localStorage.setItem('location', JSON.stringify(this.state.location));

    let pathname = `/specialists/${this.state.town}/`;
    pathname += this.state.specialization ? `${toDashed(this.state.specialization)}` : 'all-specialities';
    return this.props.push({
      pathname,
      search: queryString.stringify({
        name: this.state.name || '',
        location: this.state.location,
      }),
    });
  };

  render() {
    return (
      <div className={styles.container}>
        <form className={`search_form ${styles.form}`} action="/specialists" onSubmit={this.handleSubmit}>
          <div className={styles.queryWrapper}>
            <QueryInput
              value={this.props.specialization || this.props.name}
              specialists={this.props.specialists}
              practices={this.props.practices}
              specializations={this.props.specializations}
              fetchPractices={this.props.fetchPractices}
              fetchSpecialists={this.props.fetchSpecialists}
              fetchSpecializationsRequest={this.props.fetchSpecializationsRequest}
              setSearchValue={this.setSpecializationValue}
            />
          </div>
          <div className={styles.locationWrapper}>
            <LocationInput
              required={this.state.required}
              value={this.props.location}
              town={this.props.town}
              setLocationValue={this.setLocationValue}
              setTownValue={this.setTownValue}
            />
            <button className={`${styles.btnSearch} button has-text-centered has-background-warning`}>
              <img src="/images/new/search-icon.png" alt="" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

SearchBar.propTypes = {
  practices: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      town: PropTypes.string,
      borough: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  specialists: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })
  ),
  specializations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fetchPractices: PropTypes.func,
  resetFetchingState: PropTypes.func,
  fetchSpecialists: PropTypes.func,
  fetchSpecializationsRequest: PropTypes.func,
  name: PropTypes.string,
  specialization: PropTypes.string,
  location: PropTypes.string,
  town: PropTypes.string,
  push: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions,
      fetchSpecializationsRequest: specializationActions.fetchSpecializationsRequest,
      push,
    },
    dispatch
  );

const mapStateToProps = state => ({
  ...state.searchBar,
  specializations: specializationSelectors.getSpecializations(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBar);
